




import { APP_META } from '@/consts'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { mapActions } from 'vuex'

export default Vue.extend({
	name: 'ChangePassword',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Change Password | ${APP_META.title}`,
		}
	},
	created() {
		if (!this.$route.query.token) {
			this.$toast.error('Invalid password reset token')
			this.setAuthState({
				authentication: {
					dialog: false,
					persistent: false,
					currentTab: null,
				},
			})
			return this.$router.push('/')
		}

		this.setAuthState({
			authentication: {
				dialog: true,
				persistent: true,
				currentTab: 'changePassword',
			},
		})
	},
	methods: {
		...mapActions('auth', ['setAuthState']),
	},
})
