var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"tw-font-ave tw-text-primary tw-font-semibold tw-text-3xl md:tw-text-4.5xl"},[_vm._v(" Edit Password ")]),_c('div',{staticClass:"tw-mt-12 tw-max-w-sm"},[_c('v-form',{ref:"formRef",staticClass:"tw-grid tw-grid-cols-1 tw-gap-3 md:tw-gap-6",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onUpdatePassword.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('base-input',{attrs:{"large-label":_vm.$vuetify.breakpoint.mdAndUp,"append-icon":_vm.isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isVisiblePassword ? 'text' : 'password',"rules":[_vm.rules.required('Old Password'), _vm.rules.password],"error-messages":_vm.errors.oldPassword,"label":"Old Password"},on:{"input":function($event){_vm.errors.oldPassword = []},"click:append":function($event){_vm.isVisiblePassword = !_vm.isVisiblePassword}},model:{value:(_vm.user.oldPassword),callback:function ($$v) {_vm.$set(_vm.user, "oldPassword", $$v)},expression:"user.oldPassword"}}),_c('base-input',{attrs:{"label":"New Password","append-icon":_vm.isVisibleNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isVisibleNewPassword ? 'text' : 'password',"large-label":_vm.$vuetify.breakpoint.mdAndUp,"rules":[
					_vm.rules.required('New Password'),
					_vm.rules.password,
					function (v) { return _vm.rules.cantMatch(
							v,
							_vm.user.oldPassword,
							'Old Password',
							'New Password'
						); } ]},on:{"click:append":function($event){_vm.isVisibleNewPassword = !_vm.isVisibleNewPassword}},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}}),_c('base-input',{attrs:{"label":"Re-type Password","append-icon":_vm.isVisiblePasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isVisiblePasswordConfirmation ? 'text' : 'password',"large-label":_vm.$vuetify.breakpoint.mdAndUp,"rules":[
					_vm.rules.required('New Password'),
					function (v) { return _vm.rules.match(v, _vm.user.newPassword, 'New Password'); } ]},on:{"click:append":function($event){_vm.isVisiblePasswordConfirmation = !_vm.isVisiblePasswordConfirmation}},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('div',{staticClass:"tw-mt-14 tw-flex tw-space-x-4"},[_c('base-button',{attrs:{"dark":"","pill":"","small":"","loading":_vm.isLoading,"disabled":_vm.isLoading || !_vm.isFormValid,"type":"submit"}},[_vm._v(" Update Password ")]),_c('base-button',{attrs:{"pill":"","small":"","type":"button"},on:{"click":_vm.onClickCancel}},[_vm._v(" Cancel ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }