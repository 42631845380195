




















































































import Vue from 'vue'
import { mapActions } from 'vuex'
import { BackendError, VFormRef } from '@/types'
import { getValidators } from '@/mixins/FormMixin'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'
export default Vue.extend({
	name: 'ChangePassword',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Change Password | ${APP_META.title}`,
		}
	},
	data: () => ({
		rules: getValidators('required', 'password', 'match', 'cantMatch'),
		isLoading: false,
		isFormValid: false,
		isVisiblePassword: false,
		isVisibleNewPassword: false,
		isVisiblePasswordConfirmation: false,
		errors: {
			oldPassword: [],
		} as BackendError,
		user: {
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
	}),
	created() {
		this.$syncStack({
			name: 'Change password',
			path: this.$route.path,
		})
	},
	methods: {
		...mapActions('auth', ['logout', 'updatePassword', 'setAuthState']),
		onClickCancel() {
			;(this.$refs.formRef as VFormRef).reset()
		},
		async onUpdatePassword() {
			if (!(this.$refs.formRef as VFormRef).validate()) return

			this.isLoading = true
			let [err] = await this.updatePassword(this.user)
			this.isLoading = false

			if (err) {
				this.errors = {
					...this.errors,
					oldPassword: ['Current password is incorrect'],
				}
				return this.$toast.error('Error updating password')
			}

			this.$toast.success('Password updated successfully')
			await this.logout()
			this.setAuthState({
				authentication: {
					dialog: true,
					persistent: true,
					currentTab: 'signin',
				},
			})
		},
	},
})
